@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

* {
  padding: 0;
  margin: 0;
  background: var(--bg);
}

@font-face {
  font-family: Apfel Grotezk;
  src: url('./ApfelGrotezk-Regular.otf') format('OTF');
}

:root {
  --bg: #171717;
  --text1: #fafafa;
  --link: #838383;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Space Mono', monospace;
  color:var(--text1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 800px;
  width: 100%;
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  font-family: 'Apfel Grotezk', sans-serif;
}

p {
  text-transform: lowercase;
}

li {
  list-style: none;
}

a {
  color: var(--link);
  text-transform: lowercase;
}


a:hover, a:active {
  color: var(--text1);
}


.header-top {
  padding-bottom: .5rem;
}

.header-top h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.header-top span {
  font-size: 1rem;
}

.sep {
  height: 2px;
  width: 50%;
  background: var(--text1);
}

main {
  margin-top: 2.5rem;
  margin-bottom: 100px;
  padding-right: 4rem;
   flex: 1;
   width: 100%;
   overflow: auto;
  }

.sect > ul li {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.sect.demos, .sect.projects, .sect.themes {
  padding-bottom: 2rem;
}

.sub {
  display: block;
  font-size: 0.75rem;
}

.sub-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.demo-desc, .demo-stack {
  text-transform: lowercase;
  font-size: 0.75rem;
}


footer {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 2rem;
  height: 70px;
}

nav.social ul{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}


nav.social ul li:nth-child(1),
nav.social ul li:nth-child(2) {
  padding-right: 1rem;
}

nav.social a {
  text-decoration: none;
  font-size: 1.5rem;
}

footer a {
text-decoration: none;
}

@media (max-width: 600px) {
  .header-top span {
    font-size: 0.75rem;
    bottom: 0;
  }
  .demo-desc {
    max-width: 35ch;
  }
  main {
    margin-bottom: 70px;
    padding-right: 0.85rem;
  }
  body {
    font-size: 70%;
    padding: .5rem;
    padding-right: 0;
  }
}

